const courseSettings = {
  language: "en",
  name: "Big Data Platforms, Autumn 2023",
  siteUrl: "https://big-data-platforms-23.mooc.fi/",
  githubUrl: "https://github.com/rage/big-data-platforms-23/",
  subtitle: "Introduction to Big Data Processing Frameworks",
  slug: "big-data-platforms-23",
  tmcCourse: "",
  quizzesId: "2d7f4356-d33c-40b8-8510-ca70c54e16ef",
  tmcOrganization: "hy",
  organizationName: "University of Helsinki",
  bannerPath: "banner.jpg",
  courseId: "9b369adf-d384-427e-888e-ffb2f755f306",
}

module.exports = courseSettings
